import React from "react";
import loadable from "@loadable/component"
import { StaticImage } from "gatsby-plugin-image";

import {
  Section,
  SectionWrapper,
  Text,
} from "../../components/core/commonExports";
import { useMixpanel } from "../../context/MixpanelContext";
import { getQueryParams } from "../../utils/queryParamUtils";
import { MetaComponent } from "../../components/MetaComponent";
import GLPNavbar from "../../components/campaigns/GLPNavbar";
import { getPageName } from "../../utils/getCurrentPageName";
import ApplyInterFont from "../../components/campaigns/ApplyInterFont";
import { Button } from "../../components/core/Button";
import HeroSection from "../../components/campaigns/HeroSection";
import { HeroBg, HeroSectionContainer, HeroSectionHeading, ModalContainer, StyledBgImage } from "../../components/campaigns/glp5/SharedComponents";
import { OnDesktop } from "../../components/DesktopMobileComps";
import { HeroSectionData, QuoteModalPlaceholder } from "../../components/campaigns/SharedComponents";
import { LeadFormProvider } from "../../context/LeadFormContext";
import { useCampaignLPStates } from "../../hooks/useCampaignLPStates";

const LeadForm = loadable(() => import("../../components/LeadForm"));
const Footer = loadable(() => import("../../components/campaigns/Footer"), {
  resolveComponent: (components) => components.Footer,
});
const SelfServe = loadable(() => import("../../components/SelfServe"))

const ProtectAgainstRisks = loadable(() => import("../../components/campaigns/google5/ProtectAgainstRisks"))
const StepsToProtectBusiness = loadable(() => import("../../components/StepsToProtectBusiness/StepsToProtectBusinessGLPs"))
const QuotesFromCompanies = loadable(() => import("../../components/campaigns/glp6/QuotesFromCompanies"))
const FactsSection = loadable(() => import("../../components/campaigns/glp6/FactsSection"))
const ReviewsSection = loadable(() => import("../../components/campaigns/glp6/ReviewsSection"))
const WhyChooseVerak = loadable(() => import("../../components/campaigns/glp6/WhyChooseVerak"))

const FBLP1 = () => {
  const mixpanel = useMixpanel();

  const {
    leadformWrapperRef,
    leadformRef,
    scrollToLeadForm,
    hideQuoteCTAs,
    setHideQuoteCTAs,
    screen, 
    setScreen,
    hideHeroSection,
    isSelfServe,
    fetchEstimatedPremium,
    selfServeRef,
    handleCallScheduleClick
  } = useCampaignLPStates()

  // capture and store the query params beforehand
  getQueryParams();

  const pageName = getPageName()

  const handleGetQuoteClick = (e, ctaNumber ="") => {
    mixpanel.track(`Clicked 'Get a Quote'- ${ctaNumber}(${pageName})`);
    scrollToLeadForm()
  };

  const heroSectionDefaultComp = (
    <>
      <HeroSectionHeading style={{textAlign: "left"}}>
        Securing your business has never been easier
      </HeroSectionHeading>
      <OnDesktop>
        <Button 
          label="Get a quote" 
          uppercaseLabel={false} 
          type="flatdesign" 
          onClick={(e, ctaNumber=1) => handleGetQuoteClick(e, ctaNumber)} 
          style={{marginTop: "1rem"}}
        />
      </OnDesktop>
    </>
  )

  return (
    <>
      <ApplyInterFont />
      <MetaComponent loadYellowAiScript={false} loadFbPixelScript={false} />
      <GLPNavbar 
        hideQuoteCTA={hideQuoteCTAs || isSelfServe} 
        showCallHelplineCTA={isSelfServe} 
        newTheme 
        handleGetQuoteClick={scrollToLeadForm}
        handleCallScheduleClick={handleCallScheduleClick}
      />
      <LeadFormProvider>
        {isSelfServe ? (
          <SelfServe ref={selfServeRef} fetchEstimatedPremium={fetchEstimatedPremium} fallback={<div style={{height: "100vh", width: "100%"}} />} />
        ) : null}
        <Section background="#ffffff" style={{display: isSelfServe ? "none" : "block", marginTop: '3.8rem', position: "relative"}}>
          {screen !== "hero-image" ? (
            <HeroBg />
          ) : (
            <StyledBgImage>
              <StaticImage
                className="bg-image"
                layout="fullWidth"
                alt=""
                loading="lazy"
                placeholder="#fff"
                src="../../assets/images/campaigns/glp5-new-hero.webp"
              />
            </StyledBgImage>
          )}
          <SectionWrapper mobileStyles={{padding: "24px 0", paddingTop: hideHeroSection ? "0" : "6px"}} desktopStyles={{paddingTop: "40px"}}>
            <HeroSectionData>
              <HeroSectionContainer screen={screen} hideHeroSection={hideHeroSection}>
                <HeroSection 
                  screen={screen} 
                  setScreen={setScreen}
                  heroSectionDefaultComp={heroSectionDefaultComp}
                />
              </HeroSectionContainer>
              <ModalContainer screen={screen} hideHeroSection={hideHeroSection} ref={leadformWrapperRef}>
                <LeadForm 
                  newTheme
                  ref={leadformRef}
                  formType="google" 
                  renderAsPopup={false} 
                  setHideQuoteCTAs={setHideQuoteCTAs} 
                  style={{width: 'auto'}} 
                  fallback={<QuoteModalPlaceholder />}
                  setHeroSectionScreen={setScreen} 
                />
              </ModalContainer>
            </HeroSectionData>
          </SectionWrapper>
        </Section>
      </LeadFormProvider>
      {!isSelfServe ? <StepsToProtectBusiness mobileStyles={{background: "#F6F9FF"}} desktopStyles={{background: "#FFF"}} /> : null}
      <ProtectAgainstRisks titleStyles={{textTransform: "none"}} style={{background: "#F6F9FF"}} pageName={pageName} />
      <FactsSection style={{background: "#fff"}} showGetQuote handleGetQuoteClick={(e, ctaNumber=2) => handleGetQuoteClick(e, ctaNumber)}  />
      <QuotesFromCompanies style={{background: "#F6F9FF"}} />
      <ReviewsSection style={{background: "#F6F9FF"}} handleGetQuoteClick={(e, ctaNumber=3) => handleGetQuoteClick(e, ctaNumber)} />
      <WhyChooseVerak style={{background: "#fff"}} />
      <Section background="#F6F9FF">
        <SectionWrapper>
          <Text fontSize="36px" lineHeight="72px" mfontSize="24px" mlineHeight="32px" fontWeight="700" color="#244BCE" style={{textAlign: "left"}}>
            Always have a backup plan!
          </Text>
          <Text fontSize="32px" lineHeight="72px" mfontSize="18px" mlineHeight="32px" fontWeight="700" color="rgba(51, 51, 51, 1)" style={{textAlign: "left"}}>
            Insure your commercial property against burglary, fire, flood and 15+ threats today!
          </Text>
          <Button
            wide 
            label="Get a Quote"
            uppercaseLabel={false} 
            type="flatdesign" 
            onClick={(e, ctaNumber=4) => handleGetQuoteClick(e, ctaNumber)} 
            style={{marginTop: "1rem"}}
          />
        </SectionWrapper>
      </Section>
      <Footer style={{background: "#fff"}} />
    </>
  )
}

export default FBLP1